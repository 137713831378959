import React, { useEffect, useState } from 'react';
import { Tab, Nav, Accordion } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as HELPER from "@src/config/helper";

import { addDocument } from '@src/store/slices/common';
import { DOCUMENTS_CATEGORY_INBOUND, MAX_FILE_UPLOAD, WITHOUT_RULE_ENGINE, MAX_FILE_UPLOAD_POLICY_GENRATED, DOCUMENT_CATEGORY, APP_TYPE_AGENCY, DOC_UPLOAD_VALIDATION } from "@src/config/constant";
import { times } from 'lodash';
import ConfirmPopup from '@common/ConfirmPopUp';

const PaymentDocIcon = require('../../../../styles/webroot/images/payment-docs-icon.png');

const DocumentForm = (props: any) => {
    const [numFilesUploaded, setNumFilesUploaded] = useState(0);
    const [policyFiles, setPolicyFiles] = useState<any>([]);
    const [installmentFiles, setInstallmentFiles] = useState<any>({});
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState<any>({});

    const insuranceState = useSelector((state: any) => state.insurance);
    // in policy generted case max 10 image allow and rest 5 image allow
    const maxUploadLimit = props?.formType === "insurance-policy-generated" ? MAX_FILE_UPLOAD_POLICY_GENRATED : MAX_FILE_UPLOAD;

    useEffect(() => {
        let uploadedFiles = insuranceState?.leadDetail?.images ? insuranceState?.leadDetail?.images.filter((files: any) => props?.docId.includes(+files?.doc_id)) : [];

        if (!uploadedFiles.length && policyFiles?.length && ["payment_from_agency_to_oto_insurance", "payment_from_agency_to_insurance_partner"].includes(props.formType) && props.isDataAutoPrefilledRefNo) {
            setInstallmentFiles({});
            setPolicyFiles([]);
            props.updateIsDataAutoPrefilledRefNo(false)
        }
        let totalFileLength = uploadedFiles.length + policyFiles.length;



        setNumFilesUploaded(totalFileLength);
        props.updatedFileCount(totalFileLength);

        if (!policyFiles?.length && uploadedFiles?.length && insuranceState.leadDetail.application_type_id === APP_TYPE_AGENCY) {
            setPolicyFiles(uploadedFiles)
        }

        return () => {
            //RESET EXTRA COUNT OF FILE UPLOAD
            if (policyFiles.length) {
                // setPolicyFiles([]);
                const fileBox = document.getElementById(`fileInput`) as any;
                if (fileBox) {
                    fileBox.value = '';
                }
            }
            // setFileUploaded(false);

        }
        // eslint-disable-next-line
    }, [insuranceState?.leadDetail?.images, props.formType, policyFiles])

    useEffect(() => {
        if (insuranceState.leadDetail.application_type_id === APP_TYPE_AGENCY) {

            if (insuranceState.leadDetail.payment_tenure_id && (!["insurance-policy-generated", "soft-approval-received", "payment_from_oto_to_insurance_partner", "payment_from_insurance_partner_to_oto", "referral_fee_payout"].includes(props.formType))) {

                let docsIds: any = [...new Set(policyFiles?.map((doc: any) => doc.doc_id))] || [];

                let updatedInstalmentFiles = { ...installmentFiles }
                for (let docs of policyFiles) {
                    let findIndex = docsIds.findIndex((elm: any) => elm === docs.doc_id) + 1;
                    if (!updatedInstalmentFiles[`${findIndex}_installment_file`]) {

                        updatedInstalmentFiles[`${findIndex}_installment_file`] = [{ ...docs }]
                    } else {

                        //REMOVE DUPLICACY
                        let docExists = updatedInstalmentFiles[`${findIndex}_installment_file`]?.find((doc: any) => doc.id === docs.id)
                        if (!docExists?.id) {
                            updatedInstalmentFiles[`${findIndex}_installment_file`]?.push(docs)
                        }

                    }
                }

                setInstallmentFiles(updatedInstalmentFiles)
            }else{
                setInstallmentFiles({})

            }
        }

        // eslint-disable-next-line
    }, [policyFiles])

    const onChangeHandler = (value: any, type: any) => {

        if (!value || !value?.length) return;

        let validate = validateFiles(value, type)
        if (validate.error) return

        let files: any = validate.files;

        if (type === 'uploadFiles') {

            if (insuranceState.leadDetail.application_type_id === APP_TYPE_AGENCY && props.formType !== "soft-approval-received") {
                let savedPolicyFiles = policyFiles.filter((file: any) => file.id)
                setPolicyFiles([...savedPolicyFiles, ...files]);

            } else {//OLD FLOW

                setPolicyFiles([...files]);
            }
            let updatedFileCount = files.length;
            props.updatedFileCount(updatedFileCount);
        }

        //for installment proof documents
        if (type.includes('installment') && insuranceState.leadDetail.application_type_id !== APP_TYPE_AGENCY) {
            setInstallmentFiles({ ...installmentFiles, [type]: value })
            let updatedFileCount = files.length //+ (props.installmentDetails[type + '_count']);

            props.updatedFileCount({ type: 'installment', key: type + "_count", value: updatedFileCount });
        }

        if (type.includes('installment') && insuranceState.leadDetail.application_type_id === APP_TYPE_AGENCY) {

            let updateInstalmentDoc = { ...installmentFiles };
            if (!updateInstalmentDoc[type]) updateInstalmentDoc[type] = [];

            for (let fileVal of value) {
                updateInstalmentDoc[type].push(fileVal);
            }

            setInstallmentFiles(updateInstalmentDoc)

            let updatedFileCount = files.length + (props.installmentDetails[type + '_count']);
            props.updatedFileCount({ type: 'installment', key: type + "_count", value: updatedFileCount });
        }

    }

    const validateFiles = (value: any, type: string) => {
        const newFiles: File[] = Array.from(value);
        let allowedFileTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/jpg', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/pdf', 'application/msword', 'text/csv'];

        if (props.formType === 'insurance-policy-generated') {
            allowedFileTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/jpg', 'application/pdf'];
        }

        // Filter out files that are not allowed
        const validFiles = newFiles.filter(file => allowedFileTypes.includes(file.type));

        let uploadedFiles = insuranceState?.leadDetail?.images ? insuranceState?.leadDetail?.images.filter((files: any) => props.docId.includes(+files.doc_id)) : [];
        let totalFileLength = uploadedFiles.length + validFiles.length;

        let updatedFilesUploadedCount: any = type.includes('installment') ? props.installmentDetails[type + '_count'] : totalFileLength

        if (insuranceState.leadDetail.application_type_id === APP_TYPE_AGENCY && type.includes('installment')) {
            updatedFilesUploadedCount = updatedFilesUploadedCount + validFiles.length;
        }

        if (!validFiles || !validFiles.length) {
            toast.error('Please Upload Valid File Image or Pdf.');
            const fileBox = document.getElementById(`fileInput`) as any;
            const InstallmentFileBox = document.getElementById(`${type}Input`) as any;
            fileBox.value = ''
            if (InstallmentFileBox) InstallmentFileBox.value = "";
            return { error: true }
        }
        if (validFiles.length > maxUploadLimit || updatedFilesUploadedCount > maxUploadLimit) {
            toast.error(`You can only upload up to ${props?.formType === "insurance-policy-generated" ? MAX_FILE_UPLOAD_POLICY_GENRATED : MAX_FILE_UPLOAD} files.`);
            const fileBox = document.getElementById(`fileInput`) as any;
            const InstallmentFileBox = document.getElementById(`${type}Input`) as any;
            fileBox.value = ''
            if (InstallmentFileBox) InstallmentFileBox.value = "";
            return { error: true }
        }

        // Check the size for each file
        for (const file of validFiles) {
            if (file.size < 25000 || file.size > 5000000) {
                toast.error("file size should be between 25kb to 5mb");
                return { error: true };
            }
        }
        return { error: false, files: newFiles }
    }

    /**
     * CHECK IF SUBMIT BUTTON CLICKED THEN UPLOAD FILES
     */
    useEffect(() => {
        if (props.submitClicked) {
            handleImageUpload();
        }
        // eslint-disable-next-line
    }, [props.submitClicked]);

    /**
     * FILE UPLOAD HANDLER
     */
    const handleImageUpload = async () => {
        let selectedFiles = policyFiles;

        //for installment files, updating selectedFiles to validate each file
        if (installmentFiles && Object.keys(installmentFiles).length) {
            let filesArray: any = []
            Object.keys(installmentFiles).forEach((ele: any) => {
                filesArray = [...filesArray, ...installmentFiles[ele]]
            })
            selectedFiles = filesArray
        }

        if (selectedFiles && selectedFiles.length) {
            let doc_path = [];
            let errorFlag = false;
            let allowedExtensions = ['data:application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'data:application/pdf', 'data:application/msword', 'data:text/csv']

            let insertParams: any = new FormData()
            insertParams.append('lead_id', insuranceState.leadDetail.leadId);
            insertParams.append('request_for', 'outbound_payment');
            // insertParams.append('user_id', userId);
            for (let file of selectedFiles) {
                //CHECK IF IMAGE IS ONLY PREVIEW
                if (file && Object.keys(file).length && !file.id) {
                    const base64PreviewImg = await HELPER.toBase64(file)
                    const filetype: any = await HELPER.detectMimeType(base64PreviewImg, { allowedExtensions })
                    const fileSize = file.size

                    if (!filetype) {
                        toast.error("File format should be jpg, jpeg, png, pdf, doc, docx, csv")
                        // e.target.value = ''
                        errorFlag = true
                        break
                    } else if (fileSize < DOC_UPLOAD_VALIDATION['minSize'] || fileSize > DOC_UPLOAD_VALIDATION['maxSize']) {
                        toast.error("file size should be between 25kb to 5mb")
                        // e.target.value = ''
                        errorFlag = true
                        break
                    }
                }

                if (!errorFlag && (!installmentFiles || !Object.keys(installmentFiles).length)) {
                    if (!file.id) {
                        insertParams.append('files', file)
                    }

                    let docPath: any = {};

                    if (props.formType === 'insurance-policy-generated') {
                        docPath = { document_id: 11, document_type_id: DOCUMENT_CATEGORY['disbursal']['doc_type_id'], type: 'add' }

                    } else if (props.formType === 'soft-approval-received') {
                        docPath = { document_id: 10, document_type_id: DOCUMENT_CATEGORY['disbursal']['doc_type_id'], type: 'add' }

                    } else if (props.formType === 'inbound_payment') {
                        docPath = { document_id: 12, document_type_id: DOCUMENT_CATEGORY['postDisbursal']['doc_type_id'], type: 'add' }

                    } else if (props.formType === 'outbound_payment') {
                        docPath = { document_id: 13, document_type_id: DOCUMENT_CATEGORY['postDisbursal']['doc_type_id'], type: 'add' }

                    } else if (props.formType === 'payment_from_customer_to_partner_broker') {
                        docPath = { document_id: 14, document_type_id: DOCUMENT_CATEGORY['postDisbursal']['doc_type_id'], type: 'add' }

                    } else if (props.formType === 'payment_from_oto_to_insurance_partner') {
                        docPath = { document_id: DOCUMENT_CATEGORY['postDisbursal']['childs']['payment_from_oto_to_insurance_partner'], document_type_id: DOCUMENT_CATEGORY['postDisbursal']['doc_type_id'], type: 'add' }

                    } else if (props.formType === 'payment_from_insurance_partner_to_oto') {
                        docPath = { document_id: DOCUMENT_CATEGORY['postDisbursal']['childs']['payment_from_insurance_partner_to_oto'], document_type_id: DOCUMENT_CATEGORY['postDisbursal']['doc_type_id'], type: 'add' }

                    } else if (props.formType === 'referral_fee_payout') {
                        docPath = { document_id: DOCUMENT_CATEGORY['postDisbursal']['childs']['referral_fee_payout'], document_type_id: DOCUMENT_CATEGORY['postDisbursal']['doc_type_id'], type: 'add' }

                    } else {
                        docPath = { document_id: DOCUMENT_CATEGORY['postDisbursal']['childs'][`${props.formType}`], document_type_id: DOCUMENT_CATEGORY['postDisbursal']['doc_type_id'], type: 'add' }

                    }


                    if (!file.id) {
                        doc_path.push(docPath)
                    }
                }
            }

            if (Object.keys(installmentFiles).length) {
                let docPath = {}
                Object.keys(installmentFiles).forEach((ele: any) => {
                    let docId: any = DOCUMENTS_CATEGORY_INBOUND[ele as keyof typeof DOCUMENTS_CATEGORY_INBOUND]
                    let insFiles: any = Array.from(installmentFiles[ele])
                    if (insFiles?.length) {
                        insFiles.forEach((ele: any) => {
                            if (!ele.id) {

                                insertParams.append('files', ele)
                                docPath = { document_id: docId, document_type_id: 3, type: 'add' }
                                doc_path.push(docPath)
                            }
                        })
                    }
                })
            }

            // return false
            if (!errorFlag && doc_path.length) {
                if (props.setLoading) {
                    props.setLoading(true)
                }
                insertParams.append('doc_path', JSON.stringify(doc_path))

                let uploadDocument: any = await addDocument(insertParams)

                //RESET FILES
                setPolicyFiles([]);
                setInstallmentFiles([]);
                //if upload successfull, then update inserted image in redux
                if (uploadDocument && uploadDocument.status === 200) {
                    if (props.setLoading) {
                        props.setLoading(false)
                    }

                    if (insuranceState.leadDetail.application_type_id === APP_TYPE_AGENCY) {

                        toast.success(`Documents uploaded Successfully`)
                    } else {
                        toast.success(`${selectedFiles.length} ${selectedFiles.length > 1 ? 'Images' : 'Image'} uploaded Successfully`)

                    }

                    if (props.formType === "referral_fee_payout") {
                        setPolicyFiles(uploadDocument.data?.insertedDocuments)
                    }
                    props.resolveFileUpload({ status: true, msg: 'files_uploaded', data: uploadDocument.data?.insertedDocuments || [] });
                } else {
                    if (props.setLoading) {
                        props.setLoading(false)
                    }

                    props.resolveFileUpload({ status: false, msg: 'files_uploaded_error', data: [] });

                }

            } else {
                if (doc_path.length) {
                    props.resolveFileUpload({ status: false, msg: 'error_upload', data: [] });
                } else {
                    //AGENCY CASE HANDLE
                    props.resolveFileUpload({ status: true, msg: 'no_file_selected', data: [] });

                }

            }
        } else {

            props.resolveFileUpload({ status: true, msg: 'no_file_selected', data: [] });
        }

        const fileBox = document.getElementById(`fileInput`) as any;
        if (fileBox) fileBox.value = '';

    }

    // if Component changed Upload file count will reset
    useEffect(() => {
        setPolicyFiles([]);
    }, [props.formType])

    //DELETE PAYMENT IMAGE PREVIEWS
    const deleteImage = async (index: number, instalmentIdx: any, img: any) => {
        let tempFiles = [...policyFiles];

        if (instalmentIdx) {//REMOVE FROM INSTALMENT ARRAY

            if (img?.id) { //PREUPLOADED FILE REMOVE
                setShowConfirmation(!showConfirmation);
                setSelectedDocument(img);
                return false;


            } else {

                let tempInstallmentFiles = { ...installmentFiles };

                if (tempInstallmentFiles[`${instalmentIdx}_installment_file`][index]) {

                    tempInstallmentFiles[`${instalmentIdx}_installment_file`] = [...tempInstallmentFiles[`${instalmentIdx}_installment_file`]]
                    //REMOVE SELECTED FILE OBJECT 
                    tempInstallmentFiles[`${instalmentIdx}_installment_file`] = (tempInstallmentFiles[`${instalmentIdx}_installment_file`])?.filter((e: any, idx: number) => idx !== index);

                    setInstallmentFiles(tempInstallmentFiles)
                    //UPDATE INSTALMENT FILE COUNT
                    props.updatedFileCount({ type: 'installment', key: `${instalmentIdx}_installment_file_count`, value: tempInstallmentFiles[`${instalmentIdx}_installment_file`].length });
                } else {
                    //console.log("NO FILE TO REMOVE", policyFiles, tempFiles, installmentFiles)
                }
            }


        }

        if (insuranceState.leadDetail.application_type_id !== APP_TYPE_AGENCY) {
            tempFiles = tempFiles.filter((file: any, idx: number) => idx !== index)
            setPolicyFiles(tempFiles)
        } else {
            if (img?.id) { //PREUPLOADED FILE REMOVE
                setShowConfirmation(!showConfirmation);
                setSelectedDocument(img);
                return false;
            } else {
                tempFiles = tempFiles.filter((file: any, idx: number) => idx !== index)
                setPolicyFiles(tempFiles);
            }
        }

    }

    const checkImageType = (img: any) => {
        let isPdfFile = false;
        if ((img?.type && img?.type?.indexOf("pdf") !== -1) || (img.path && img.path.indexOf("pdf") !== -1)) {
            isPdfFile = true
        }
        return isPdfFile;
    }

    const confirmYes = async () => {

        let updatedPolicyFiles = policyFiles.filter((file: any) => file.id !== selectedDocument.id)
        setPolicyFiles(updatedPolicyFiles);
        props.setLoading(true);

        let formDataDoc = new FormData();
        formDataDoc.append("lead_id", insuranceState.leadDetail.id)
        formDataDoc.append("doc_path", JSON.stringify([{
            "id": selectedDocument.id, "document_id": selectedDocument.doc_id, "type": "delete", "document_type_id": selectedDocument.doc_type_id
        }]))
        let uploadDocument: any = await addDocument(formDataDoc)

        if (uploadDocument?.status === 200) {
            toast.success("Document deleted successfully")
            if (insuranceState.leadDetail.payment_term_id === 1) {
                setPolicyFiles((prev: any) => prev.filter((img: any) => img.id !== selectedDocument.id))

            } else {
                let tempInstallmentFiles = { ...installmentFiles };
                // eslint-disable-next-line
                for (let [instalmentIdx, doc] of Object.keys(tempInstallmentFiles).entries()) {
                    tempInstallmentFiles[`${instalmentIdx + 1}_installment_file`] = (tempInstallmentFiles[`${instalmentIdx + 1}_installment_file`])?.filter((e: any, idx: number) => e.id !== selectedDocument.id);

                }
                setInstallmentFiles(tempInstallmentFiles)
            }

            // let updatedPolicyFiles = insuranceState.leadDetail?.images?.filter((img:any)=> img.id !== selectedDocument.id); 
            // let updatedState = { ...insuranceState.leadDetail, "images": updatedPolicyFiles }
            // dispatch(updateLeadDetailsInStore(updatedState));

        }

        props.setLoading(false);


        setShowConfirmation(false)
        setSelectedDocument({})
    }


    // let hideUploadButton = (insuranceState?.leadDetail?.is_payment_freezed || (insuranceState?.leadDetail?.payment_way === 2 && insuranceState?.leadDetail?.bulk_payment_id)) || false
    let hideUploadButton = (insuranceState?.leadDetail?.is_payment_freezed) || false

    return (
        <div className="row">
            {
                !["payment_from_agency_to_oto_insurance", "payment_from_oto_to_insurance_partner", "referral_fee_payout", "payment_from_agency_to_insurance_partner", "payment_from_insurance_partner_to_oto"].includes(props.formType) &&
                <div className="lead-form-heading col-md-12 doc-uplaod-heading">
                    <h3>Documents Upload</h3>
                </div>
            }
            <div className="col-md-12">

                {
                    ["payment_from_agency_to_oto_insurance", "payment_from_oto_to_insurance_partner", "referral_fee_payout", "payment_from_agency_to_insurance_partner", "payment_from_insurance_partner_to_oto"].includes(props.formType)
                        ?
                        <div className="agency-payment-doc-view">
                            <Accordion defaultActiveKey="0">

                                {
                                    ["payment_from_agency_to_oto_insurance", "payment_from_agency_to_insurance_partner"].includes(props.formType)
                                        && props?.installmentDetails?.payment_tenure_id && props?.installmentDetails?.payment_tenure_label
                                        ?
                                        <div className='contentContainer w-100'>
                                            {
                                                times((props.installmentDetails.payment_tenure_label).split(" ")[0], (idx: number) => {
                                                    let incrementedIndx = idx + 1;

                                                    let fetchInstalmentFile = []

                                                    if (installmentFiles?.[`${incrementedIndx + "_installment_file"}`]) {

                                                        for (let files of installmentFiles?.[`${incrementedIndx + "_installment_file"}`]) {
                                                            fetchInstalmentFile.push(files)
                                                        }
                                                    }

                                                    return (
                                                        <Accordion.Item eventKey={`${idx}`}>
                                                            <Accordion.Header>
                                                                <div className='upload-proof-accordion-box'>

                                                                    <div className="card color-one" >
                                                                        <div className="roung-bg">

                                                                            <img src={PaymentDocIcon} alt="doc-icon" />
                                                                        </div>
                                                                    </div>
                                                                    <span className="card-txt">Upload Payment Proofs {incrementedIndx} {incrementedIndx === 1 ? "*" : ""}</span>

                                                                </div>
                                                            </Accordion.Header>
                                                            <Accordion.Body>



                                                                {
                                                                    (fetchInstalmentFile.length) ?
                                                                        fetchInstalmentFile?.map((img: any, index: number) =>
                                                                            <div className="col-md-4 upload-image-proof" key={`doc-${index}`}>
                                                                                {
                                                                                    // (!insuranceState?.leadDetail?.is_payment_freezed && !insuranceState.leadDetail.insurance_agency_payment_id) &&
                                                                                    // !img?.id &&
                                                                                    (!img?.id || (img?.id && insuranceState.leadDetail.payment_way === 1 && !insuranceState?.leadDetail?.is_payment_freezed)) &&

                                                                                    <span className='close-icn' onClick={() => { deleteImage(index, incrementedIndx, img) }}><i className='ic-clearclose'></i></span>
                                                                                }

                                                                                <div key={index} className="image-container-agency">
                                                                                    {
                                                                                        (checkImageType(img))
                                                                                            ?
                                                                                            <i className={`ic-pdf-file icons-normal t-accent`} ></i>
                                                                                            :
                                                                                            <img src={img?.path || URL.createObjectURL(img)} alt={`preview-${index}`} className="upload-icn" loading="lazy" />

                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        ) : ''
                                                                }
                                                                {

                                                                    (
                                                                        (insuranceState?.leadDetail?.is_payment_freezed && insuranceState?.leadDetail?.payment_reference_arr?.[idx]?.['payment_id'])

                                                                        && (props.formType === "payment_from_agency_to_oto_insurance" || props.formType === "payment_from_agency_to_insurance_partner"))
                                                                        ? "" :
                                                                        <div className="img-bx-select-opt col-md-4 add-basic-doc">
                                                                            <div className="image-bx more-file-upload add-insurance-partner">


                                                                                <div className="document-image-container ">
                                                                                    <div role="presentation" className="add-document-proof upload-input-bx">
                                                                                        {
                                                                                            <input id='fileInput' type="file" title="" className="upload-image-input" name="documents[]" onChange={(e: any) => onChangeHandler(e.target.files, incrementedIndx + "_installment_file")} multiple /*disabled={disableField}*/ />
                                                                                        }
                                                                                        <i className="ic-add"> </i>
                                                                                        <span>Upload</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                }

                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    )
                                                })
                                            }
                                        </div>
                                        :
                                        <>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>
                                                    <div className='upload-proof-accordion-box'>

                                                        <div className="card color-one" >
                                                            <div className="roung-bg">
                                                                <img src={PaymentDocIcon} alt="doc-icon" />

                                                            </div>
                                                        </div>
                                                        <span className="card-txt">Upload Payment Proof*</span>

                                                    </div>
                                                </Accordion.Header>
                                                <Accordion.Body>



                                                    {
                                                        policyFiles?.map((img: any, index: number) =>
                                                            <div className="col-md-4 upload-image-proof" key={`doc-${index}`}>
                                                                {
                                                                    // !insuranceState.leadDetail.insurance_agency_payment_id &&
                                                                    (!img?.id || (["payment_from_agency_to_insurance_partner", "payment_from_agency_to_oto_insurance"].includes(props.formType) && insuranceState.leadDetail.payment_way === 1)) &&
                                                                    <span className='close-icn' onClick={() => { deleteImage(index, null, img) }}><i className='ic-clearclose'></i></span>
                                                                }

                                                                <div key={index} className="image-container-agency">
                                                                    {
                                                                        (checkImageType(img))
                                                                            ?
                                                                            <i className={`ic-pdf-file icons-normal t-accent`} ></i>
                                                                            :
                                                                            <img src={img?.path || URL.createObjectURL(img)} alt={`preview-${index}`} className="upload-icn" />

                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                    {
                                                        ((hideUploadButton && (props.formType === "payment_from_agency_to_oto_insurance" || props.formType === "payment_from_agency_to_insurance_partner"))
                                                            ||
                                                            (insuranceState?.leadDetail?.insurance_agency_commission_id && (["payment_from_oto_to_insurance_partner", "payment_from_insurance_partner_to_oto"].includes(props.formType)))
                                                            ||
                                                            (props?.leadDetails?.referral_fee_payout_id && props.formType === "referral_fee_payout")
                                                        ) ? "" :
                                                            <div className="img-bx-select-opt col-md-4 add-basic-doc">
                                                                <div className="image-bx more-file-upload add-insurance-partner">


                                                                    <div className="document-image-container ">
                                                                        <div role="presentation" className="add-document-proof upload-input-bx">
                                                                            {
                                                                                <input id='fileInput' title="" type="file" className="upload-image-input" name="documents[]" onChange={(e: any) => onChangeHandler(e.target.files, 'uploadFiles')} multiple /*disabled={disableField}*/ />
                                                                            }
                                                                            <i className="ic-add"> </i>
                                                                            <span>Upload</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    }

                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </>
                                }

                            </Accordion>
                        </div>
                        :
                        <Tab.Container id="left-tabs-example" defaultActiveKey={["soft-approval-received", "insurance-policy-generated"].includes(props.formType) ? "second" : "third"}>

                            <Nav variant="pills" className="flex-column tab-line">
                                <div className="tab-list">


                                    {
                                        ["soft-approval-received", "insurance-policy-generated"].includes(props.formType) && insuranceState.leadDetail.sub_status_id > 2 &&
                                        <Nav.Item className={!["soft-approval-received", "insurance-policy-generated"].includes(props.formType) ? 'pointer-event-disbaled' : ''} style={{ display: "none" }}>
                                            <Nav.Link eventKey="second">Disbursal</Nav.Link>
                                        </Nav.Item>
                                    }
                                    {
                                        ["inbound_payment", "outbound_payment", "payment_from_customer_to_partner_broker"].includes(props.formType) && insuranceState.leadDetail.sub_status_id > 2 && //["inbound_payment", "outbound_payment"].includes(props.formType) &&
                                        <Nav.Item className={!["inbound_payment", "outbound_payment", "payment_from_customer_to_partner_broker"].includes(props.formType) ? 'pointer-event-disbaled' : ''} style={{ display: "none" }}>
                                            <Nav.Link eventKey="third">Post Disbursal</Nav.Link>
                                        </Nav.Item>
                                    }
                                </div>
                            </Nav>
                            <Tab.Content>

                                <Tab.Pane eventKey="second">
                                    <div className='contentWrapper'>
                                        <div className='approvalWrapper'>
                                            {numFilesUploaded > 0 && <i className="customeCheck ic-check"></i>}
                                            <p>{props.formType === "soft-approval-received" ? (`Soft Approval ${+insuranceState.leadDetail.rule_engine_id === WITHOUT_RULE_ENGINE ? '*' : ''}`) : "Insurance Policy *"}</p>
                                            <span className='cutomeStepper'>{numFilesUploaded}/{maxUploadLimit}</span>

                                        </div>

                                        <div className='btn-line btn-download'>
                                            <label htmlFor='fileInput' className='uploadButton'>
                                                <i className="ic-upload"></i>
                                                Upload
                                            </label>
                                            <input
                                                type='file'
                                                id='fileInput'
                                                name='fileInput'
                                                style={{ display: 'none' }}
                                                onChange={(e) => onChangeHandler(e.target.files, 'uploadFiles')}
                                                disabled={numFilesUploaded > maxUploadLimit}
                                                multiple
                                            />

                                            {props?.errField?.policyDocument && (
                                                <span className="error-txt" style={{ left: "10px" }}>
                                                    {props.errField['policyDocument']}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="third">
                                    <div className='contentWrapper'>
                                        {
                                            props?.installmentDetails?.payment_tenure_id && props?.installmentDetails?.payment_tenure_label
                                                ?
                                                <div className='contentContainer w-100'>
                                                    {
                                                        times((props.installmentDetails.payment_tenure_label).split(" ")[0], (idx: number) => {
                                                            let incrementedIndx = idx + 1;

                                                            let isPrefilled = (insuranceState?.leadDetail?.installment_details?.[incrementedIndx + "_installment"]?.amount || insuranceState?.leadDetail?.installment_details?.[incrementedIndx + "_installment"]?.date)
                                                            let fileCount = props.installmentDetails[incrementedIndx + "_installment_file_count"]
                                                            let isDisabled = (isPrefilled || fileCount > MAX_FILE_UPLOAD)

                                                            return (
                                                                <div className='li' key={idx}>
                                                                    {
                                                                        fileCount >= 0 &&
                                                                        <div className='approvalWrapperForDocument w-100 p-10-tb'>
                                                                            {fileCount > 0 && <i className="customeCheck ic-check"></i>}
                                                                            <p className={`${!["inbound_payment", 'outbound_payment'].includes(props.formType) ? 'prooftext w-62' : 'w-62'} ${fileCount > 0 ? ' w-55' : ''}`} >{"Proof of " + incrementedIndx + HELPER.nthNumber(incrementedIndx) + " Installment"}</p>
                                                                            <span className={`cutomeStepper customSteppernew`}>{fileCount}/{MAX_FILE_UPLOAD}</span>
                                                                        </div>
                                                                    }
                                                                    <div className={`${isDisabled ? "btn-line disable-class" : "btn-line btn-download "}`}>
                                                                        <label htmlFor={incrementedIndx + '_installment_fileInput'} className='uploadButton'>
                                                                            <i className="ic-upload"></i>
                                                                            Upload
                                                                        </label>
                                                                        {
                                                                            isDisabled
                                                                                ?
                                                                                <input
                                                                                    type='file'
                                                                                    id={incrementedIndx + '_installment_fileInput'}
                                                                                    name={incrementedIndx + '_installment_fileInput'}
                                                                                    style={{ display: 'none' }}
                                                                                    onChange={(e) => { }}
                                                                                    disabled={true}
                                                                                    multiple
                                                                                />
                                                                                : <input
                                                                                    type='file'
                                                                                    id={incrementedIndx + '_installment_fileInput'}
                                                                                    name={incrementedIndx + '_installment_fileInput'}
                                                                                    style={{ display: 'none' }}
                                                                                    onChange={(e) => onChangeHandler(e.target.files, incrementedIndx + "_installment_file")}
                                                                                    disabled={false}
                                                                                    multiple
                                                                                />
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                :
                                                <>
                                                    {numFilesUploaded >= 0 &&
                                                        <div className='approvalWrapperForDocument'>
                                                            {numFilesUploaded > 0 && <i className="customeCheck ic-check"></i>}
                                                            <p className={`${!["inbound_payment", 'outbound_payment'].includes(props.formType) ? 'w-62' : ''}`} >{props.formType === "inbound_payment" ? "Proof of Inbound Payment" : props.formType === "outbound_payment" ? "Proof of Outbound Payment" : "Proof of Customer Payment to Partner/Broker*"}</p>
                                                            <span className='cutomeStepper customSteppernew'>{numFilesUploaded}/{MAX_FILE_UPLOAD}</span>
                                                        </div>
                                                    }
                                                    <div className='btn-line btn-download'>
                                                        <label htmlFor='fileInput' className='uploadButton'>
                                                            <i className="ic-upload"></i>
                                                            Upload
                                                        </label>
                                                        <input
                                                            type='file'
                                                            id='fileInput'
                                                            name='fileInput'
                                                            style={{ display: 'none' }}
                                                            onChange={(e) => onChangeHandler(e.target.files, 'uploadFiles')}
                                                            //disabled={numFilesUploaded > MAX_FILE_UPLOAD}
                                                            multiple
                                                        />
                                                        {props?.errField?.customerToPartnerDocument && (
                                                            <span className="error-txt" style={{ left: "10px" }}>
                                                                {props.errField['customerToPartnerDocument']}
                                                            </span>
                                                        )}
                                                    </div>
                                                </>
                                        }



                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>

                }
            </div>

            {
                showConfirmation &&
                <ConfirmPopup confirmNo={() => { setShowConfirmation(false); setSelectedDocument({}); }} confirmYes={confirmYes} message={"Are you sure you want to delete this document?"} />
            }
        </div>
    );

}


export default DocumentForm;